// @file Native Bridge Legacy

import { $ } from '@@/bits/jquery'
import { listen } from '@@/native_bridge/listen'
import * as stateActions from '@@/native_bridge/native_actions_map'
import { ActionableStore, NativeBridgeWindow } from '@@/native_bridge/types'

function buildLegacyNativeBridge({ document }, { dispatch }) {
  const callbacks: { [key: string]: Function | null } = {}

  /*************************************
   * NATIVE BRIDGE METHODS
   *************************************/

  // This callback was removed from our mobile app in Oct 2018.
  // Recommend removal: Oct 2020
  const remake = () => (callbacks.remake ? callbacks.remake() : $(document).trigger('remake'))

  // Still being used by mobile app reliant on Riot/web-based settings panel (VERSION >= 18).
  // Recommend removal: July 2021
  const backOnePanel = () => $(document).trigger('backOnePanel')
  const forwardOnePanel = (panelName) => callbacks.forwardOnePanel?.(panelName)
  const saveSettings = () => (callbacks.saveSettings ? callbacks.saveSettings() : $(document).trigger('saveSettings'))
  const savePrivacy = () => (callbacks.savePrivacy ? callbacks.savePrivacy() : $(document).trigger('savePrivacy'))

  // Used in Riot/web-based settings panel. Only swapped out in version 90 (July 2019)
  // Recommend removal: July 2021
  const filePickDone = (url) =>
    callbacks.filePickDone ? callbacks.filePickDone(url) : $(document).trigger('post:done', url)

  // Used in settings panel, but only to cater to VERSION <= 90 (July 2019)
  // Recommend removal: July 2021
  const receivingFile = (filePurpose) => callbacks.receivingFile?.(filePurpose)

  // TODO: Deprecate
  // Used in mobile app
  const createPostWithMedia = (options: { url?: string } = {}) =>
    options.url &&
    dispatch('post/createPost', {
      attributes: {
        attachment: options.url,
        wish_content: {
          attachment_props: {
            url: options.url,
          },
          is_processed: false,
        },
      },
    })

  const registerable = {
    remake,
    forwardOnePanel,
    saveSettings,
    savePrivacy,
    filePickDone,
    receivingFile,
  }
  const nonRegisterable = {
    backOnePanel,
    createPostWithMedia,
  }

  /*************************************
   * BUILD HASH
   *************************************/
  const register = {}
  const deregister = {}
  Object.keys(registerable).forEach((key) => {
    register[key] = (callback) => (callbacks[key] = callback)
    deregister[key] = () => (callbacks[key] = null)
  })
  const trigger = { ...registerable, ...nonRegisterable }

  // This mirrors what used to be window.$nativeBridge.dispatch(), declared in native_bridge/install.ts
  // In case window.$nativeBridge gets deprecated, this instance of dispatch can replace it.
  const nativeBridgeDispatch = (fnName: string, ...args: any[]): void => {
    const actions = stateActions[fnName]
    // Do an early return if the actions are undefined since they are likely unsupported anymore due to old mobile app versions.
    if (actions === undefined) return
    dispatch(actions.apply(null, args))
  }

  return { dispatch: nativeBridgeDispatch, register, deregister, trigger }
}

function initializeListeners({ store: { dispatch } }) {
  // MOBILE APPS: 20 <= VERSION <= 90 (Jul 2019: native settings panel)
  listen('create_post', (attributes) => dispatch('post/createPost', { attributes }))
  listen('padlet_settings_hide', () => dispatch('settings/hideSettingsPanel'))
  listen('padlet_settings_update', (msg: any) =>
    dispatch('settings/updateAndValidatePreviewAttributes', { ...msg.preview_attributes }),
  )
}

export default function install(window: NativeBridgeWindow, store: ActionableStore) {
  if (!window.ww) window.ww = {}
  window.ww.nativeBridge = { ...window.ww.nativeBridge, ...buildLegacyNativeBridge(window, store) }
  initializeListeners({ store })
}
